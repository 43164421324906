.slider-loading {
  .slick-track {
    display: inline-block !important;
    overflow: hidden;
    height: 300px;
  }

  .slick-slide {
    display: inline-block !important;
    width: 320px !important;
  }

  @media screen and (min-width: 768px) {
    .slick-slide {
      width: 384px !important;
    }
  }
}
